import React from "react";

const FooterText = () => (
  <>
    Data by NEXON Open API, Font by MapleStory
    <br />
    © 2024. 메짱. All rights reserved.
    <br />
    Contact by sideoff0217@naver.com
  </>
);

export default FooterText;
